import * as animation1 from '../assets/animations/ki_l1_v01.json'
import * as animation2 from '../assets/animations/ki_l2_v01.json'
import * as animation3 from '../assets/animations/ki_l3_v01.json'
import * as animation4 from '../assets/animations/ki_l4_v01.json'
import * as animation5 from '../assets/animations/ki_l5_v01.json'

// @ts-ignore
const InformationCardHomeDesc = [
    {
        title: "Сопровождение&nbsp;<br /> уголовных дел",
        desc: "Комплексное сопровождение расследования сложных много эпизодных уголовных дел экономической направленности, в том числе контрабанда, неуплата налогов, коррупционные действия, неисполнение обязательств по договорам, корпоративные и арбитражные споры, осложненные уголовно-правовым элементом, преднамеренное банкротство, фиктивное банкротство, заказные дела со стороны бизнес-конкурентов и партнеров по бизнесу. Наши эксперты успешно добиваются прекращения уголовных дел органами следствия на стадии предварительного следствия. ",
        animation: animation4,
        link: '/soprovozhdenie-ugolovnyh-del'
    },
    {
        title: "Параллельный&nbsp;<br /> импорт",
        desc: "Комплексное сопровождение параллельного импорта «под ключ» брендированной продукции, в том числе высокотехнологического оборудования и компонентов (включая чувствительные товары) в обход санкционных запретов США и ЕС (правило NO Russia) на экспорт в РФ. Мы не только проводим системную диверсификацию внешнеторговых закупок через цепочку дружественных компаний, зарегистрированных в третьих странах, во избежание отказа реализации груза в Россию, но и используем уникальную альтернативную конфиденциальную архитектуру расчетов.",
        animation: animation5,
        link: '/parallelnyy-import-oborudovaniya'
    },
    {
        title: "Комплексная защита&nbsp;<br /> должников от кредиторов",
        desc: "Нестабильные условия рынка и жесткая санкционная политика недружественных государств нередко провоцируют ситуации, при которых исполнение кредитных обязательств субъектом предпринимательства становится затруднительным. Своевременное обращение на ранней стадии к команде наших экспертов позволит не только полностью исключить риски наступления негативных последствий для должников, но и выйти победителями в противостоянии с кредиторами. ",
        animation: animation1,
        link: '/zashchita-dolzhnikov-ot-kreditorov'
    },
    {
        title: "Сопровождение процедуры&nbsp;<br />  банкротства под ключ",
        desc: "Комплексное сопровождение Вашей компании на всех стадиях банкротства с целью полного прекращения процедуры банкротства и сохранения Вашего бизнеса, или с целью ликвидации предприятия с полным безвозвратным списанием всех долгов и с превентивным исключением рисков субсидиарной ответственности. Только наши эксперты смогут собрать, легендировать и легализовать доказательную базу об отсутствии наличия признаков фиктивного и преднамеренного банкротства. ",
        animation: animation3,
        link: '/yuridicheskoe-soprovozhdenie-bankrotstva'
    },
    {
        title: "Защита от субсидиарной &nbsp;<br /> ответственности",
        desc: "Не дожидайтесь проблем. С командой наших экспертов Вы всегда на шаг впереди, заранее предотвращая риски субсидиарной ответственности. Там где другие видят риски, мы ищем и находим нестандартные решения, организуем превентивную защиту, сохраняем личные активы. Защищаем контролирующих должника лиц (КДЛ) от субсидиарной ответственности и добиваемся полного списания задолженности. ",
        animation: animation2,
        link: '/zashchita-ot-subsidiarnoy-otvetstvennosti'
    },
]

export default InformationCardHomeDesc;