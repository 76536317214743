import React from 'react';
import AdditionalInformationHome from "../../../../components/additional-information/Home";
import ButtonsK from "../../../../components/ButtonsK";
import AdditionalInformationHomeImg from "../../../../assets/img/additional-information-home.svg";
import Documentation from "../documentation/Documentation";
interface BankruptcyAssistanceProps {
    styles: React.CSSProperties;
}

const BankruptcyAssistance: React.FC<BankruptcyAssistanceProps> = ({ styles }) => {
    return (
        <div>
            <div style={{ fontSize: 'clamp(20px, 2.1vw, 25px)' }} className="leading-[37.5px] max-lg:leading-[35px] tracking-[-0.1px] mt-20 mb-10 max-sm:text-[20px] max-sm:mt-[40px] max-sm:mb-[30px] max-sm:tracking-normal">
                Организуем импорт брендированной продукции, высокотехнологического оборудования и компонентов,
                в том числе чувствительных товаров из стран Европы, США, Канады, Австралии и Японии в Россию
            </div>
            <h2 style={{ fontSize: 'clamp(20px, 2.1vw, 25px)' }} className="leading-[37.5px] max-lg:leading-[35px] tracking-[-0.1px] mt-[47px] mb-[37px] max-sm:text-[20px] max-sm:mt-[40px] max-sm:mb-[30px] max-sm:tracking-normal">
                Параллельный импорт в Россию. Успешно обходим санкционное правило No Russia
            </h2>
            <div className="numbered-list">
                <ol className="flex flex-col gap-[9px]">
                    <li className="leading-[170%] ml-[45px] max-sm:text-[15px] max-sm:leading-[160%]">
                        Проводим внешнеторговые закупки через цепочку реально работающих дружественных компаний,
                        зарегистрированных в третьих странах, не обладающими признаками «технических» компаний с
                        точки зрения санкционных комплаенс-офицеров западных компаний-производителей.
                        Сводим до минимума количество отказов в реализации чувствительных товаров.
                    </li>
                    <li className="leading-[170%] ml-[45px]  max-sm:text-[15px] max-sm:leading-[160%]">
                        Дружественная компания в нейтральной стране собирает необходимый пакет документации,
                        доказывающий, что закупаемая продукция и конечный пользователь находится именно в её стране,
                        а не в России и оформляет при необходимости сертификат конечного пользователя
                        «end-use certificate» (EUC) для комплаенс-контроля западных компаний-производителей и для
                        таможенных органов при оформлении экспортных деклараций ЕХ-1.
                    </li>
                    <li className="leading-[170%] ml-[45px]  max-sm:text-[15px] max-sm:leading-[160%]">
                        В целях обеспечения конфиденциальности параллельного импорта и фактического конечного
                        пользователя - российского заказчика, мы используем уникальную альтернативную конфиденциальную
                        архитектуру расчетов. Платежи проводятся через так называемую децентрализованную финансовую
                        систему, которая изначально не предполагает корреспондентских отношений и посредников в лице
                        крупных банков, что делает практически невозможным проследить потоки финансовых операций.
                    </li>
                    <li className="leading-[170%] ml-[45px]  max-sm:text-[15px] max-sm:leading-[160%]">
                        При импорте высокотехнологического оборудования, до ввоза продукции в РФ,
                        при необходимости отключаем модуль удаленной диагностики или модифицируем программное
                        обеспечение (меняя географию региона), делая невозможным для производителя отследить
                        конечный регион использования оборудования.
                    </li>
                    <li className="leading-[170%] ml-[45px]  max-sm:text-[15px] max-sm:leading-[160%]">
                        Обеспечиваем максимально оперативную доставку груза до пункта таможенного оформления в России.
                        Проводим таможенную очистку и выпускаем продукцию в свободное обращение на таможенную
                        территорию ЕАЭС. Передаем груз конечному заказчику с подписанием товарной накладной
                        по форме ТОРГ-12 и акта приема-передачи.
                    </li>
                </ol>
            </div>
            <div style={{ background: 'rgba(187, 37, 37, 0.08)' }} className="border-l-4 border-[#BB2525] mt-10 py-[28px] px-[31px] flex items-center">
                <p className="text-[#BB2525]">
                    Не консультируем по схемам работы
                </p>
            </div>
            <div className="flex flex-col gap-[27px] mt-[83px]">
                <h2 style={{ fontSize: 'clamp(20px, 2.1vw, 25px)' }} className="leading-[37.5px] max-md:leading-[35px] mb-[10px] tracking-[-0.1px] max-sm:text-[20px] max-sm:tracking-normal">
                    Краткий экскурс в проблематику параллельного импорта&nbsp;<br className="max-lg:hidden"/>для тех, кто в танке
                </h2>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Наша страна столкнулась с беспрецедентным уровнем санкций после начала СВО на территории Украины.
                    Россия по состоянию на март 2024 года возглавила антирейтинг стран по числу введенных санкций,
                    как в количественном, так и в качественном отношении (ранее список возглавлял Иран).
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    К сожалению, российский бизнес вынужден сейчас просчитывать самые различные сценарии и заранее
                    готовиться к дальнейшим еще более жестким санкциям в случае возможной эскалации конфликта.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Меры по прямому санкционному воздействию на бизнес в РФ со стороны недружественных государств – это
                    новая реальность. Играть на легальном поле становится все сложнее, а экономическая целесообразность
                    и результативность находится в прямой зависимости от применяемых механизмов и схем работы.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    В действующем законодательстве отсутствует определение термина «параллельный импорт».
                    При этом под ним стандартно понимается ввоз зарубежных товаров без разрешения правообладателя,
                    так называемый «серый импорт». Однако, ситуация резко изменилась с началом СВО.
                    Правительство РФ с целью недопущения товарного дефицита на внутреннем рынке приступило к разработке
                    правовых механизмов параллельного импорта, заключающихся в утверждении перечня товаров
                    (групп товаров) в отношении которых более не требуются разрешения правообладателя торговых марок
                    при соблюдении определенных условий.
                </p>
                <div style={{ background: 'rgba(8, 108, 128, 0.1)' }} className="border-l-4 border-[#097990] py-[26px] pl-[31px] pr-[70px] flex items-center">
                    <p className="text-[#086C80] leading-[28.8px] tracking-[0.45px]">
                        Крайне важно отличать правовой институт параллельного импорта от поставок в Россию
                        контрафактной продукции. В рамках параллельного импорта осуществляются поставки
                        только оригинальных товаров.
                    </p>
                </div>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Основой правового регулирование параллельного импорта в России является приказ Минпромторга
                    России № 2701 от 21 июля 2023 года {`"Об утверждении перечня товаров (групп товаров),
                    в отношении которых не применяются положения статей 1252, 1254, пункта 5 статьи 1286.1,
                    статей 1301, 1311, 1406.1, подпункта 1 статьи 1446, статей 1472, 1515 и 1537 Гражданского кодекса
                    Российской Федерации при условии введения указанных товаров (групп товаров) в оборот за пределами
                    территории Российской Федерации правообладателями (патентообладателями), а также с их согласия"`}
                    (Зарегистрировано в Минюсте России 04.08.2023 N 74633) в редакции 16 января 2024 года.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Надзорные органы недружественных государств, видя конкретные шаги правительства РФ по либерализации
                    законодательства о параллельном импорте, падение запрещающей роли ТРОИС
                    (таможенного реестра объектов интеллектуальной собственности) в охране товарных знаков
                    правообладателей, предприняли не ординарные меры по усилению внешнего санкционного давления.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Так параллельный импорт особенно резко усложнился после выхода указа президента
                    США Байдена от 22 декабря 2023 года – «О принятии дополнительных мер в отношении вредоносной
                    деятельности Российской Федерации». В нем Минфин США получает полномочия блокировать деятельность
                    иностранных банков на более широких основаниях. Достаточно того, чтобы финансовая организация
                    хотя бы косвенно участвовала в сделке по преодолению Россией технологических ограничений или
                    способствовала её производственной активности.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Данный указ Байдена резко усиливает значимость рисков «вторичных санкций» для дружественных стран.
                    В итоге средний срок платежа из России в Турцию и Китай увеличился до недели,
                    в некоторых случаях расчеты идут больше месяца. Проблемы с платежами у отечественных компаний
                    возникли в январе – феврале 2024 года, банки Турции и Китая стали чаще отклонять оплату.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Дальнейшие ужесточения и ограничения в расчетах с российскими клиентами появились уже со стороны
                    большинства банков дружественных стран. Формального ограничения на операции между российскими
                    банками и банками дружественных стран нет, международные санкции пока не ограничивают возможность
                    совершения платежей из не под санкционных банков. Но ситуация осложняется понятием
                    «вторичных санкций». Уже даже банки Казахстана, Армении, Грузии стали все чаще и чаще отклонять
                    оплату или блокировать валютные переводы на неопределенное время.
                </p>
                <div style={{ background: 'rgba(8, 108, 128, 0.1)' }} className="border-l-4 border-[#097990] py-[26px] pl-[31px] pr-[70px] flex items-center">
                    <p className="text-[#086C80] leading-[28.8px] tracking-[0.45px]">
                        Несмотря на попытки российских властей, договорится, тенденция к ужесточению нарастает.
                        Банки повсеместно усиливают комплексную проверку клиентов на предмет связей с РФ.
                        Обслуживание корпоративных клиентов с российскими корнями – вне зависимости от страны
                        регистрации компании – на данный момент во многих иностранных банках приостановлено.
                    </p>
                </div>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Помимо тотального ограничения трансграничных расчетов, другая основная сложность – получение
                    разрешения на экспорт, так как одно дело – закон о параллельном импорте в России, а другое – вывоз
                    этого товара из-за рубежа. Недружественные страны в массовом порядке стали вводить во внешнюю
                    торговлю правило «NoRussia». Оно запрещает как экспорт, так и реэкспорт определенных
                    продуктов в РФ или в другую страну для использования в России. Все контракты должны в обязательном
                    порядке перейти на правило «No Russia» к 20 марта 2024 года. Одновременно с этим большинство
                    европейских и американских экспедиций стали вводить ограничения на оформление разрешений на вывоз
                    продукции при малейшем подозрении, что данная продукция может быть реэкспортирована в РФ из третьей
                    страны. Опасаясь вторичных санкций и общей антироссийской истерии отказы в оформлении экспортных
                    деклараций на вывоз продукции стали случаться повсеместно, и часто под надуманными предлогами.
                </p>
                <p className="leading-[27.2px] max-sm:text-[15px]">
                    Такая к сожалению, по состоянию на весну 2024 года,
                    вкратце общая ситуация по проблематике параллельного импорта.
                </p>
            </div>
            <Documentation />
        </div>
    );
};

export default BankruptcyAssistance;