const homeGoodQualityData = [
    {
        title: "Честность",
        desc: "Соблюдаем Золотое Правило во всем, поступать честно и правильно. Держим свои обещания, не обещаем то, что невозможно реализовать. Честно говорим обо всех обстоятельствах, которых не удастся избежать."
    },
    {
        title: "Нацеленность на&nbsp;<br /> итоговый результат",
        desc: "Перевернем Ваше дело с ног на голову в поисках того самого «чёрного лебедя», и при любых условиях и обстоятельствах, какими бы сложными они не были на первый взгляд, полностью сфокусируемся на достижении поставленных целей."
    },
    {
        title: "Комфорт",
        desc: "Мы выстраиваем с клиентами долгосрочные взаимные доверительные отношения, исходя из принципа «внутренней справедливости», берём на себя ответственность за свои действия, всегда открыты к коммуникации - мы на связи 24/7"
    },
]

export default homeGoodQualityData;